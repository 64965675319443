body {
    background: #eee;
  }
  
  .name {
    color: blue;
  }
  
  .work {
    font-weight: bold;
    font-size: 15px;
  }
  
  .about span {
    font-size: 13px;
  }
  
  .v-profile {
    color: blue;
    cursor: pointer;
  }
  
  .box {
    -webkit-box-shadow: 13px 12px 5px -10px rgba(196, 194, 196, 0.72);
    -moz-box-shadow: 13px 12px 5px -10px rgba(196, 194, 196, 0.72);
    box-shadow: 13px 12px 5px -10px rgba(196, 194, 196, 0.72);
  }
  
  .col-md-3 {
    margin-top: 10px;
  }
  
  .container .box {
    transition: all 0.3s;
  }
  
  .container .box:hover {
    transform: scale(1.05);
  }
  
  .container .box img {
    width: 150px; /* Increase the size of the profile picture */
  }
  
  